<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-n3">a) Which of the following 0.10 M solutions has the lowest pH?</p>

      <v-radio-group
        v-model="inputs.multipleChoiceAnswer"
        class="ml-6 mb-0"
        :disabled="!allowEditing"
      >
        <v-radio v-for="option in options" :key="option.value" class="my-1" :value="option.value">
          <template #label>
            <stemble-latex :content="option.text" />
          </template>
        </v-radio>
      </v-radio-group>

      <p class="mb-2">b) I think this because:</p>

      <p class="mb-n2">
        <s-textarea v-model="inputs.explainedResponse" outlined />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'Question514',
  components: {
    STextarea,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        multipleChoiceAnswer: null,
        explainedResponse: null,
      },
      options: [
        {text: '$\\ce{HClO}$', value: 'hypochlorous'},
        {text: '$\\ce{HClO2}$', value: 'chlorous'},
        {text: '$\\ce{HClO3}$', value: 'chloric'},
        {text: '$\\ce{HBrO3}$', value: 'bromic'},
        {text: '$\\ce{HIO3}$', value: 'iodic'},
      ],
    };
  },
};
</script>
